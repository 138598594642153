<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" size="small" icon="el-icon-s-tools" v-if="isAdmin">设置制度类别</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="systemList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column label="序号" type="index" width="100" :index='indexMethod'> </el-table-column>
        <el-table-column prop="ruleName" label="制度名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top" v-if="isAdmin">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看制度内容" placement="top">
              <el-button type="primary" @click="open('system',scope.row.ruleContent)" size="mini" icon="el-icon-tickets" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看附件" placement="top">
              <el-button type="primary" @click="open('check',scope.row)" size="mini" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表单 -->
    <el-dialog title="修改制度" :visible.sync="systemShow" width="50%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="system" ref="systemForm" label-width="120px">
        <el-form-item label="录入方式" prop="inputWay">
          <el-radio-group v-model="system.inputWay" size="small">
            <el-radio v-for="(item,i) in [{id:0,text:'文本'},{id:1,text:'附件'},{id:3,text:'均可'}]" :key="i" :label="item.id">
              {{item.text}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="制度编号" prop="code">
          <el-input v-model="system.code" placeholder="请输入制度编号" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="制度类型" prop="ruleType">
          <el-select v-model="system.ruleType" placeholder="请选择制度类型" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in ruleTypeList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制度名称" prop="ruleName">
          <el-input v-model="system.ruleName" placeholder="请输入制度名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="公布情况" prop="publishFlag">
          <el-radio-group v-model="system.publishFlag" size="small">
            <el-radio v-for="(item,i) in [{id:0,text:'已公布'},{id:1,text:'未公布'}]" :key="i" :label="item.id">
              {{item.text}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传图片" v-show="system.inputWay===1||system.inputWay===3">
          <el-upload class="upload-demo" ref="upload" drag action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :limit="1" :headers="{Authorization:token}" :on-success="uploadSuccess" :on-error="uploadError">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="制度内容" prop="ruleContent" v-show="system.inputWay===0||system.inputWay===3">
          <editor-bar v-model="system.ruleContent" :isClear="isClear" @change="changeContent"></editor-bar>
        </el-form-item>
        <el-form-item label="操作流程" prop="operationFlow">
          <el-input v-model="system.operationFlow" placeholder="请输入操作流程" size="small" clearable></el-input>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="systemShow = false">取 消</el-button>
        <el-button type="primary" @click="updateSystem">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog title="附件" :visible.sync="enclosureShow" width="30%">
      <el-image :src="enclosure" fit="fill" :lazy="true" :preview-src-list="[enclosure]"></el-image>
    </el-dialog>
    <!-- 内容 -->
    <el-dialog title="制度内容" :visible.sync="contentShow" width="50%">
      <div style="width:100%;height:100%;padding:0 10px;" v-html="content"></div>
    </el-dialog>
  </div>
</template>

<script>
import EditorBar from '../../../../components/Editor'
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  components: {
    EditorBar
  },
  data () {
    return {
      drawingName: '',
      token: '',
      systemList: [],
      ruleTypeList: [],
      systemId: '',
      system: {
        appendix: '',
        code: '',
        inputWay: 0,
        operationFlow: '',
        publishFlag: 0,
        ruleContent: '',
        ruleName: '',
        ruleType: ''
      },
      systemCopy: {},
      isClear: false,
      systemShow: false,
      enclosureShow: false,
      contentShow: false,
      enclosure: '',
      content: '',
      loading: false,
      rules: {
        buildingType: [
          { required: true, message: '请选择建筑类别', trigger: 'change' }
        ],
        buildingId: [
          { required: true, message: '请选择所属建筑物', trigger: 'change' }
        ],
        drawType: [
          { required: true, message: '请选择图纸类型', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入图纸名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.getruleType()
    this.systemCopy = JSON.stringify(this.system)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    indexMethod (index) {
      const curpage = 1// 单前页码，具体看组件取值
      const limitpage = 10 // 每页条数，具体是组件取值
      return index + 1 + (curpage - 1) * limitpage
    },
    async initTable () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      this.loading = true
      const { data: result } = await this.$axios.get('/fireSafeManageRule/list', { params: data })
      if (result.code === 200) {
        this.systemList = result.data
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    async addSystem () {
      this.$refs.systemForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/fireSafeManageRule/add', this.system)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.systemShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async updateSystem () {
      this.$refs.systemForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/fireSafeManageRule/edit/${this.systemId}`, this.system)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.systemShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async delSystem (id) {
      const confirm = await this.$confirm('此操作将永久删除该布局, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/fireSafeManageRule/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'edit':
          this.system = JSON.parse(JSON.stringify(row))
          this.systemShow = true
          this.systemId = row.id
          this.$nextTick(() => {
            this.$refs.systemForm.clearValidate()
            this.$refs.upload.clearFiles()
          })
          break
        case 'check':
          if (!row.appendix) {
            this.$message('该条数据暂无附件')
          } else {
            this.enclosure = row.appendix
            this.enclosureShow = true
          }
          break
        case 'system':
          if (!row) {
            this.$message('该条数据暂无内容')
          } else {
            this.content = row
            this.contentShow = true
          }
          break
      }
    },
    async getruleType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'ruleType' } })
      if (result.code === 200) {
        this.ruleTypeList = result.data
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.system.appendix = res.data
        this.$message.success('上传成功')
      }
    },
    uploadError (err) {
      if (err) {
        this.$message.error('上传失败')
      }
    },
    clear () {
      this.system = JSON.parse(this.systemCopy)
      this.$refs.systemForm.clearValidate()
    },
    changeContent (val) {

    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  box-shadow: none !important;
}
.container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border: none !important;
}
</style>
